import React from "react";
import Link from "next/link";
import { ArrowDownRight, ArrowupRight } from "~/components/icons";
import { LinkTrackers } from "~/components/reusables/link-trackers";

const MenuItem = ({ title, items, className = "", external }) => {
  return (
    <div className={className}>
      {title && (
        <div>
          <div className="flex items-center gap-3">
            <div className="hidden w-6 lg:block">
              {external ? <ArrowupRight /> : <ArrowDownRight />}
            </div>
            <h5 className="md:font-p text-nowrap text-[18px] text-sky-blue lg:text-white xl:text-[17px]">
              {title}
            </h5>
          </div>
          <div className="my-5 h-[1px] w-full bg-[#04BBE9]"></div>
        </div>
      )}
      {items &&
        items.map((menu, i) =>
          menu.text == "The Shift" ? (
            <LinkTrackers
              href={menu.url}
              target={menu.internal ? "_self" : "_blank"}
              segmentMsg="Outshift newsletters clicked"
              segmentOpt={{
                link_label: "The Shift",
                location: "Navigation bar - header",
                location_detail: "About us - company",
                resource_interaction: "view newsletter subscription form",
              }}
              key={i}
              className="my-3 flex gap-3"
            >
              <div className="hidden h-1 w-6 lg:block"></div>
              <p className="font-p underline-offset-2 hover:underline xl:text-[17px]">
                {menu.text}
              </p>
            </LinkTrackers>
          ) : (
            <Link
              href={menu.url}
              target={menu.internal ? "_self" : "_blank"}
              key={i}
              className="my-3 flex gap-3"
            >
              <div className="hidden h-1 w-6 lg:block"></div>
              <p className="font-p underline-offset-2 hover:underline xl:text-[17px]">
                {menu.text}
              </p>
            </Link>
          ),
        )}
    </div>
  );
};
export default MenuItem;
