import Image from "next/image";
import { AspectRatio } from "../ui/aspect-ratio";
import { cn } from "~/lib/utils";
import StrapiBlocksRenderer from "~/components/reusables/strapi-blocks-renderer";
import { LinkTrackers } from "~/components/reusables/link-trackers";

const ProductMenuCard = ({
  product,
  horizontalLayout,
  forceHeight = "null",
}) => {
  const blogImage = product.img?.data?.attributes?.url;
  const isPDf = product.url.startsWith(
    "https://outshift-headless-cms-s3.s3.us-east-2.amazonaws.com",
  );

  return (
    <div>
      <LinkTrackers
        href={`${product.url}`}
        target="_blank"
        className={
          horizontalLayout ? "group flex flex-col gap-5" : "group flex gap-8"
        }
        segmentMsg={
          product.url.startsWith(
            "https://outshift-headless-cms-s3.s3.us-east-2.amazonaws.com",
          )
            ? "Downloadable clicked"
            : "Resource clicked"
        }
        // TODO: remove hardcoded tracking message and read from strapi
        segmentOpt={{
          link_label: product.title,
          location: "Navigation bar - header",
          location_detail: "Resources",
          resource_interaction: isPDf ? "read a PDF" : "view a video",
          file_name: isPDf
            ? "Internet_of_Agents_Whitepaper.pdf"
            : "Cisco Quantum Summit 2025: Pathways to Practical and Functional Quantum Networking",
          resource_type: isPDf ? "white paper" : "webinar/video",
          resource_author: isPDf && "Vijoy Pandey, Ph.D",
          is_gated_asset: "false",
        }}
      >
        <div
          className={cn(
            "relative mx-auto aspect-[15/7] h-auto w-full flex-[.45] overflow-hidden rounded-xl border border-[#4C4C4C]",
            forceHeight,
          )}
        >
          <div className="w-full">
            <AspectRatio ratio={horizontalLayout ? 300 / 140 : 200 / 120}>
              <Image
                unoptimized
                src={blogImage}
                fill
                alt="Featured home blog"
                className="w-[90%] rounded-xl object-cover transition-all duration-700 group-hover:scale-105"
                sizes="100%"
              />
            </AspectRatio>
          </div>
        </div>
        <div className="flex-[.55]">
          {product.title && (
            <div className="mt-1 text-[#A6A6A6]">
              <h5 className="font-p-sm">{product.title}</h5>
            </div>
          )}
          {product.description && (
            <div
              className={
                horizontalLayout
                  ? "mt-1 text-[#A6A6A6]"
                  : "mt-1 text-[#A6A6A6] md:mt-0"
              }
            >
              <StrapiBlocksRenderer content={product.description} />
            </div>
          )}
        </div>
      </LinkTrackers>
    </div>
  );
};

export default ProductMenuCard;
