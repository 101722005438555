"use client";
import React, { useState } from "react";
import {
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuTrigger,
} from "~/components/ui/navigation-menu";
import MenuCTAItem from "./menu-cta-item";
import BlogCard from "~/components/reusables/blog-card";
import ProductMenuCard from "~/components/reusables/product-menu-card";
import MenuItem from "./menu-item";
import NavBanner from "./nav-banner";
import { motion, AnimatePresence } from "framer-motion";
import { SearchIcon } from "~/components/icons";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "components/ui/search-dialog";
import { SearchBar } from "./search-bar";

const DesktopNav = ({ menuItems, eyeBrowLink }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div>
        <div>
          <NavigationMenuList className="flex w-full justify-between gap-6">
            {menuItems?.map((item, itemIndex) => {
              return (
                <NavigationMenuItem key={item.title}>
                  <NavigationMenuTrigger className="group relative flex cursor-default select-none items-center justify-between gap-[2px] rounded-[4px] bg-black px-0 py-6 text-primary-gray outline-none focus:shadow-[0_0_0_2px] group-data-[state=open]:text-white">
                    {item.title == "Blog" ? (
                      <NavigationMenuLink
                        href={"/blog"}
                        className="font-h5 group-data-[state=open]:text-white"
                      >
                        {item.title}
                      </NavigationMenuLink>
                    ) : (
                      <>
                        {item.title == eyeBrowLink.text && (
                          <div className="absolute right-0 top-[-10px] rounded-xl border border-blue-light px-[7px] py-[1px] text-xs">
                            <p className="text-[10px] text-sky-blue">
                              {eyeBrowLink.eyebrow}
                            </p>
                          </div>
                        )}
                        <div className="font-h5 group-data-[state=open]:text-white">
                          {item.title}
                        </div>
                      </>
                    )}
                    <div className="absolute bottom-0 hidden h-[5px] w-full bg-gradient-blue-purple group-data-[state=open]:block"></div>
                  </NavigationMenuTrigger>

                  <NavigationMenuContent className="relative z-[10000] rounded-none bg-black pb-20 text-white">
                    <div className="absolute top-0 z-20 h-[1px] w-full bg-gradient-blue-purple"></div>
                    <AnimatePresence>
                      <motion.div
                        className="w-[calc(100vw-16px)]"
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.45, type: "easeIn" }}
                      >
                        <div className="container relative z-20">
                          <div className="flex flex-col-reverse gap-6 py-8 md:gap-8 lg:gap-12 xl:flex-row xl:py-12">
                            <div className="flex-[.5]">
                              <MenuCTAItem cta={item.CTA} />
                              {item.filteredBlog ? (
                                <div className="py-5">
                                  <h6 className="font-h6">
                                    {item.featuredTitle}
                                  </h6>
                                  <div className="grid grid-cols-1 gap-5 pt-5 md:grid-cols-2">
                                    {item.filteredBlog.map((blog, i) => (
                                      <div key={`blog-${i}`}>
                                        <BlogCard blog={blog} size="menu" />
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ) : item.featuredBlogs.data.length > 0 ? (
                                <div className="py-5">
                                  <h6 className="font-h6">
                                    {item.featuredTitle}
                                  </h6>
                                  <div className="grid grid-cols-1 gap-5 pt-5 md:grid-cols-2">
                                    {item.featuredBlogs.data.map((blog, i) => (
                                      <div key={`blog-${i}`}>
                                        <BlogCard blog={blog} size="menu" />
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ) : (
                                item.featuredCard.length > 0 && (
                                  <div className="py-5">
                                    <h6 className="font-h6">
                                      {item.featuredTitle}
                                    </h6>
                                    <div
                                      className={
                                        item.horizontalLayout
                                          ? "grid grid-cols-1 gap-5 pt-5 md:grid-cols-2"
                                          : "grid grid-cols-1 gap-5 pt-5"
                                      }
                                    >
                                      {" "}
                                      {item.featuredCard.map((product, i) => (
                                        <div key={`blog-${i}`}>
                                          <ProductMenuCard
                                            product={product}
                                            horizontalLayout={
                                              item.horizontalLayout
                                            }
                                          />
                                          {i == 0 && !item.horizontalLayout && (
                                            <div className="mb-3 mt-8 h-[1px] w-full bg-[#024B5D]"></div>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                            <div
                              className={`grid flex-[.5] ${item.menu3Title ? "grid-cols-3" : "grid-cols-6"} gap-5 md:gap-16`}
                            >
                              <MenuItem
                                title={item.menu1Title}
                                items={item.menu1Items}
                                className={
                                  item.menu3Title ? "col-span-1" : "col-span-3"
                                }
                                external={item.menu1External}
                              />
                              <MenuItem
                                title={item.menu2Title}
                                items={item.menu2Items}
                                className={
                                  item.menu3Title ? "col-span-1" : "col-span-3"
                                }
                                external={item.menu2External}
                              />
                              <MenuItem
                                title={item.menu3Title}
                                items={item.menu3Items}
                                external={item.menu3External}
                              />
                            </div>
                          </div>
                        </div>
                      </motion.div>
                    </AnimatePresence>
                    <NavBanner item={item} />
                  </NavigationMenuContent>
                </NavigationMenuItem>
              );
            })}
            <Dialog open={open} onOpenChange={setOpen}>
              <DialogTrigger aria-label="Open search dialog">
                <SearchIcon color="#B5B5B5" size={30} />
              </DialogTrigger>
              <DialogContent className="">
                <div className="flex-grow">
                  <SearchBar setOpen={setOpen} />
                </div>
              </DialogContent>
            </Dialog>
          </NavigationMenuList>
        </div>
      </div>
    </>
  );
};

export default DesktopNav;
