"use client";
import React, { useState, useRef } from "react";
import { useRouter } from "next/navigation";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { SearchIcon } from "~/components/icons";

export const SearchBar = ({ setOpen }) => {
  const [input, setInput] = useState("");
  const router = useRouter();

  const searchRef = useRef<any | null>(null);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      router.push(`/resources?search=${input}`);
      setOpen(false);
    }
  };

  return (
    <>
      <div
        className="sticky top-0 z-50  flex w-full text-white md:container md:h-full md:items-center md:justify-center"
        ref={searchRef}
      >
        <div className="relative w-full">
          <div className="bg-gradient-search-bar-vertical z-50 w-full rounded-full p-[1px]">
            <div className="flex w-full items-center justify-between rounded-full bg-[#161617] px-6 py-3 text-[#727272] md:justify-around md:gap-3">
              <Button
                className=" flex self-center hover:bg-opacity-10"
                variant={"ghost"}
                size="icon"
                aria-label="Search"
                onClick={() => {
                  router.push(`/resources?search=${input}`);
                  setOpen(false);
                }}
              >
                <SearchIcon color="#727272" size={30} />
              </Button>
              <Input
                placeholder="Search keywords"
                className="border-0 text-lg font-light text-[#727272] shadow-none placeholder:text-[#727272] focus-visible:ring-0"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
