import React from "react";
import { ArrowUpRight } from "~/components/icons";
import StrapiBlocksRenderer from "~/components/reusables/strapi-blocks-renderer";
import { LinkTrackers } from "~/components/reusables/link-trackers";

const MenuCTAItem = ({ cta }) => {
  return (
    <div className="rounded-md border border-[#666666] p-5 md:p-4">
      <LinkTrackers
        href={cta.url}
        target={"_self"}
        segmentMsg="Outshift newsletters clicked"
        segmentOpt={{
          link_label: "Subscribe to  the Shift",
          location: "Navigation bar - header",
          location_detail: "Navigation banner",
          resource_interaction: "view newsletter subscription form",
        }}
      >
        <div className="flex flex-col items-start justify-between gap-2 md:flex-row md:items-center">
          {cta.title && (
            <div className="flex-[.5]">
              <StrapiBlocksRenderer content={cta.title} />
            </div>
          )}
          {cta.description && (
            <div className="flex-[.55]">
              <StrapiBlocksRenderer content={cta.description} />
            </div>
          )}
          <div className="">
            <div className="block">
              <div className="block rounded-md border border-white text-white md:hidden mt-1">
                <ArrowUpRight size={28} color="white" />
              </div>
              <div className="hidden rounded-md border border-white text-white md:block">
                <ArrowUpRight size={40} color="white" />
              </div>
            </div>
          </div>
        </div>
      </LinkTrackers>
    </div>
  );
};
export default MenuCTAItem;
